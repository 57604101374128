import React from 'react';
import styles from './About.module.scss';
import Terminal from "./Terminal";
import { Box, Link, Typography } from "@mui/material";
import { info } from "../../info/Info";

export default function About({ innerRef }) {

    function aboutMeText() {
        return (
            <>
                <Typography variant="h4" component="h2" gutterBottom className={styles.heading}>
                    🌟 Hello! I’m <strong>Senior Research Scientist</strong> at Meta
                </Typography>
                
                <Box className={styles.highlight}>
                    <Typography variant="body1" gutterBottom>
                        I specialize in <strong>Multimodal Reasoning</strong> as part of the <strong>Llama Team</strong>. In this role, I delve into the fascinating world of multimodal reasoning, pushing the boundaries of how different modalities interact and enhance AI capabilities. Prior to joining Llama, I worked on multi-view video diffusion models and 3D technologies, enabling the generation of 3D assets and advanced media editing capabilities. 🎨✨ My journey also includes impactful internships at Microsoft Research Lab and Huawei Noah's Ark.
                    </Typography>
                </Box>

                <Typography variant="h5" component="h3" gutterBottom className={styles.subheading}>
                    🚀 Collaborations
                </Typography>
                <Typography variant="body1" gutterBottom>
                    I’m fortunate to work alongside amazing professionals such as <Link href="https://www.robots.ox.ac.uk/~vedaldi/" target="_blank" rel="noopener">Andrea Vedaldi</Link>, <Link href="https://www.linkedin.com/in/natalia-neverova-14066133/?originalSubdomain=uk" target="_blank" rel="noopener">Natalia Neverova</Link>, and <Link href="https://scholar.google.com/citations?user=j7MW4iYAAAAJ&hl=en" target="_blank" rel="noopener">Ce Liu</Link>, among other amazing members of the Llama team. Together, we’re advancing the frontiers of computer vision and multimodal reasoning.
                </Typography>

                <Typography variant="h5" component="h3" gutterBottom className={styles.subheading}>
                    🎓 My Mentors
                </Typography>
                <ul className={styles.list}>
                    <li><Link href="https://jkokkin.github.io/" target="_blank" rel="noopener">Iasonas Kokkinos (UCL)</Link></li>
                    <li><Link href="https://www.linkedin.com/in/stamatis-lefkimmiatis-37855930/" target="_blank" rel="noopener">Stamatis Lefkimmiatis (SkolTech)</Link></li>
                    <li><Link href="https://slp-ntua.github.io/potam/" target="_blank" rel="noopener">Alexandros Potamianos (NTUA)</Link></li>
                </ul>

                <Typography variant="h5" component="h3" gutterBottom className={styles.subheading}>
                    👩‍🎓 Supervising Students
                </Typography>
                <ul className={styles.list}>
                    <li><Link href="https://junlinhan.github.io/" target="_blank" rel="noopener">Junlin Han</Link>: Ph.D. at Oxford</li>
                    <li><Link href="https://www.linkedin.com/in/shalini-maiti-a76a2b86/?originalSubdomain=uk" target="_blank" rel="noopener">Shalini Maiti</Link>: Ph.D. at UCL</li>
                    <li><Link href="https://erictuanle.com/" target="_blank" rel="noopener">Eric Tuan Le</Link>: Intern at Meta (UCL)</li>
                    <li><Link href="https://lukemelas.github.io/" target="_blank" rel="noopener">Luke Melas-Kyriazi</Link>: Intern at Meta (Oxford)</li>
                    <li><Link href="https://yufan-ren.com/" target="_blank" rel="noopener">Yufan Ren</Link>: Intern at Meta (EPFL)</li>
                    <li><Link href="https://ktertikas.github.io/" target="_blank" rel="noopener">Konstantinos Tertikas</Link>: Intern at Meta (UOA)</li>
                    <li><Link href="https://morrisalp.github.io/" target="_blank" rel="noopener">Morris Alper</Link>: Intern at Meta (Tel Aviv University)</li>
                </ul>
            </>
        );
    }

    return (
        <Box
            ref={innerRef}
            display='flex'
            flexDirection='column'
            alignItems='center'
            mt='3rem'
            id='about'
            className={styles.container}
        >
            <Terminal text={aboutMeText()} />
        </Box>
    );
}
